const toast_notifications = {
  success: {
    register: 'Erfolgreich registriert',
    login: 'Login erfolgreich',
    logout: 'Logout erfolgreich',
    representative_add: 'Vertreter erfolgreich hinzugefügt',
    representative_update: 'Vertreter erfolgreich aktualisiert',
    representative_delete: 'Vertreter erfolgreich gelöscht',
    message_sent: 'Nachricht erfolgreich gesendet',
    package_update: 'Paket erfolgreich aktualisiert',
    good_update: 'Gut erfolgreich aktualisiert',
    good_create: 'Gut erfolgreich hinzugefügt',
    profile_update: 'Profil erfolgreich aktualisiert',
    password_update: 'Passwort erfolgreich aktualisiert',
    password_reset:
      'Link zum Zurücksetzen des Passworts an Ihre E-Mail gesendet',
    asset_delete: 'Asset erfolgreich gelöscht',
    property_update: 'Eigenschaft erfolgreich aktualisiert',
    property_create: 'Eigenschaft erfolgreich hinzugefügt',
    beneficiary_update: 'Begünstigter erfolgreich aktualisiert',
    beneficiary_create: 'Begünstigter erfolgreich hinzugefügt',
    beneficiary_delete: 'Begünstigter erfolgreich gelöscht',
    reminder_service: 'Erinnerungsservice erfolgreich aktualisiert',
    childAdd: 'Kind erfolgreich hinzugefügt',
    childDelete: 'Kind erfolgreich gelöscht',
    childEdit: 'Kind erfolgreich aktualisiert',
    partner_message: 'Nachricht erfolgreich gesendet',
    invitation_sent: 'Einladung erfolgreich gesendet',
    document_submit: 'Dokument erfolgreich eingereicht',
    deathReport: 'Todesbericht erfolgreich eingereicht',
    teamInvite: 'Teammitglied erfolgreich eingeladen',
    addAdvisor: 'Berater erfolgreich hinzugefügt',
    platform_update: 'Plattformeinstellungen erfolgreich aktualisiert',
    cancel_invitation: 'Einladung erfolgreich abgebrochen',
    resend_invitation: 'Einladung erfolgreich erneut gesendet',
    delete_team_member: 'Teammitglied erfolgreich gelöscht',
    joinWaitList: 'Warteliste erfolgreich beigetreten',
    widgetRequest: 'Widget-Anfrage erfolgreich gesendet',
    createUser: 'Benutzer erfolgreich erstellt',
    deleteUser: 'Benutzer erfolgreich gelöscht',
    assignedNewPartner: 'Neuer Partner erfolgreich zugewiesen',
    disconnectFromPartner: 'Erfolgreich von Partner getrennt',
    consulationRequest: 'Beratung erfolgreich angefordert',
    addedAsset: 'Vermögenswert erfolgreich hinzugefügt',
    updatedAsset: 'Vermögenswert erfolgreich aktualisiert',
    deletedAsset: 'Vermögenswert erfolgreich gelöscht',
    addedFinancialInstitution: 'Finanzinstitut erfolgreich hinzugefügt',
    updatedFinancialInstitution: 'Finanzinstitut erfolgreich aktualisiert',
    deletedFinancialInstitution: 'Finanzinstitut erfolgreich gelöscht',
    userTransfer: 'Benutzer erfolgreich übertragen',
    addDigitalAsset: 'Digitaler Vermögenswert erfolgreich hinzugefügt',
    editDigitalAsset: 'Digitaler Vermögenswert erfolgreich aktualisiert',
    deleteDigitalAsset: 'Digitaler Vermögenswert erfolgreich gelöscht',
    saving: 'Erfolgreich gespeichert',
    otpCode: 'OTP-Code erfolgreich gesendet',
  },
  error: {
    register: 'Registrierung fehlgeschlagen',
    userExists:
      'Es gibt bereits einen Benutzer mit dieser E-Mail. Bitte verwenden Sie diese E-Mail zum Anmelden.',
    login: 'Login fehlgeschlagen, überprüfen Sie die Anmeldeinformationen',
    logout: 'Logout fehlgeschlagen',
    package_failed: 'Paket konnte nicht aktualisiert werden',
    representative_add: 'Vertreter konnte nicht hinzugefügt werden',
    representative_update: 'Vertreter konnte nicht aktualisiert werden',
    representative_delete: 'Vertreter konnte nicht gelöscht werden',
    good_update: 'Gut konnte nicht aktualisiert werden',
    good_create: 'Gut konnte nicht hinzugefügt werden',
    profile_update: 'Profil konnte nicht aktualisiert werden',
    password_update: 'Passwort konnte nicht aktualisiert werden',
    password_reset:
      'Link zum Zurücksetzen des Passworts konnte nicht gesendet werden',
    asset_delete: 'Asset konnte nicht gelöscht werden',
    property_update: 'Eigenschaft konnte nicht aktualisiert werden',
    property_create: 'Eigenschaft konnte nicht hinzugefügt werden',
    beneficiary_update: 'Begünstigter konnte nicht aktualisiert werden',
    beneficiary_create: 'Begünstigter konnte nicht hinzugefügt werden',
    beneficiary_delete: 'Begünstigter konnte nicht gelöscht werden',
    reminder_service: 'Erinnerungsservice konnte nicht aktualisiert werden',
    childAdd: 'Kind konnte nicht hinzugefügt werden',
    childDelete: 'Kind konnte nicht gelöscht werden',
    childEdit: 'Kind konnte nicht aktualisiert werden',
    partner_message: 'Nachricht konnte nicht gesendet werden',
    invitation_sent: 'Einladung konnte nicht gesendet werden',
    document_submit: 'Dokument konnte nicht eingereicht werden',
    deathReport:
      'Todesbericht konnte nicht eingereicht werden, vielleicht haben Sie ihn bereits eingereicht',
    teamInvite: 'Teammitglied konnte nicht eingeladen werden',
    addAdvisor: 'Berater konnte nicht hinzugefügt werden',
    platform_update: 'Plattformeinstellungen konnten nicht aktualisiert werden',
    cancel_invitation: 'Einladung konnte nicht abgebrochen werden',
    resend_invitation: 'Einladung konnte nicht erneut gesendet werden',
    delete_team_member: 'Teammitglied konnte nicht gelöscht werden',
    joinWaitList: 'Warteliste konnte nicht beigetreten werden',
    widgetRequest: 'Widget-Anfrage konnte nicht gesendet werden',
    createUser: 'Benutzer existiert bereits',
    deleteUser: 'Benutzer konnte nicht gelöscht werden',
    assignedNewPartner: 'Neuer Partner konnte nicht zugewiesen werden',
    disconnectFromPartner: 'Konnte nicht von Partner getrennt werden',
    consulationRequest: 'Beratung konnte nicht angefordert werden',
    addedAsset: 'Vermögenswert konnte nicht hinzugefügt werden',
    updatedAsset: 'Vermögenswert konnte nicht aktualisiert werden',
    deletedAsset: 'Vermögenswert konnte nicht gelöscht werden',
    addedFinancialInstitution: 'Finanzinstitut konnte nicht hinzugefügt werden',
    updatedFinancialInstitution:
      'Finanzinstitut konnte nicht aktualisiert werden',
    deletedFinancialInstitution: 'Finanzinstitut konnte nicht gelöscht werden',
    userTransfer: 'Benutzer konnte nicht übertragen werden',
    addDigitalAsset: 'Digitaler Vermögenswert konnte nicht hinzugefügt werden',
    editDigitalAsset:
      'Digitaler Vermögenswert konnte nicht aktualisiert werden',
    deleteDigitalAsset: 'Digitaler Vermögenswert konnte nicht gelöscht werden',
    saving: 'Speichern fehlgeschlagen',
    otpCode: 'OTP-Code konnte nicht gesendet werden',
  },
};

export default toast_notifications;
