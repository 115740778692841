import { ElementType, Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from '#/components/shared/ui/LoadingScreen';
import useAuth from '../lib/hooks/useAuth';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

// eslint-disable-next-line import/prefer-default-export

export const Home = Loadable(lazy(() => import('#/pages/main/Home')));

export const AboutUs = Loadable(lazy(() => import('#/pages/main/AboutUs')));

export const PreventiveCheckUp = Loadable(
  lazy(() => import('#/pages/main/PreventiveCheckUp'))
);

export const Imprint = Loadable(lazy(() => import('#/pages/main/Imprint')));

export const PrivacyPolicy = Loadable(
  lazy(() => import('#/pages/main/PrivacyPolicy'))
);

// export const TermConditions = Loadable(
//   lazy(() => import('#/pages/main/TermsConditions'))
// );

export const AGB = Loadable(lazy(() => import('#/pages/main/AGB')));

export const Dashboard = Loadable(
  lazy(() => import('#/pages/dashboard/Dashboard'))
);
export const GuestDashboard = Loadable(
  lazy(() => import('#/pages/dashboard/GuestDashboard'))
);

export const SDocumentHistory = Loadable(
  lazy(
    () =>
      import('#/components/pages/SuccessorDashboard/extras/document-history')
  )
);

export const Consulting = Loadable(
  lazy(() => import('#/pages/dashboard/AdminConsulting'))
);

export const Consultant = Loadable(
  lazy(() => import('#/pages/dashboard/Consultant'))
);

export const CreateUser = Loadable(
  lazy(() => import('../pages/dashboard/CreateUser'))
);

export const ContactUs = Loadable(
  lazy(() => import('../pages/dashboard/ContactUs'))
);
export const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// export const Register = Loadable(lazy(() => import('../pages/auth/Register')));
export const VerifyCode = Loadable(
  lazy(() => import('../pages/auth/VerifyCode'))
);

export const AcceptInvitation = Loadable(
  lazy(() => import('../pages/compact/AcceptInvitation'))
);
export const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);
export const NewPassword = Loadable(
  lazy(() => import('../pages/auth/NewPassword'))
);
export const Page403 = Loadable(lazy(() => import('../pages/compact/Page403')));
export const Contact = Loadable(lazy(() => import('../pages/compact/Contact')));
export const Page404 = Loadable(lazy(() => import('../pages/compact/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/compact/Page500')));
export const Maintenance = Loadable(
  lazy(() => import('../pages/compact/Maintenance'))
);
export const ComingSoon = Loadable(
  lazy(() => import('../pages/compact/ComingSoon'))
);

export const OnboardingStepOne = Loadable(
  lazy(
    () =>
      import(
        '#/components/pages/PensionProvision/onboarding/steps/registration'
      )
  )
);

export const WelcomeCard = Loadable(
  lazy(
    () =>
      import(
        '#/components/pages/PensionProvision/onboarding/steps/registration/welcome-card'
      )
  )
);

export const OnboardingStepTwo = Loadable(
  lazy(
    () =>
      import(
        '#/components/pages/PensionProvision/onboarding/steps/profiling/second-step'
      )
  )
);

export const OnboardingStepThree = Loadable(
  lazy(
    () =>
      import(
        '#/components/pages/PensionProvision/onboarding/steps/profiling/third-step'
      )
  )
);

export const SituationCheck = Loadable(
  lazy(() => import('../pages/main/SituationCheck'))
);

export const PensionProvision = Loadable(
  lazy(() => import('../pages/compact/PensionProvision'))
);
export const ThankyouCard = Loadable(
  lazy(() => import('../components/pages/PensionProvision/ThankyouCard'))
);

export const ProfileSettings = Loadable(
  lazy(() => import('../pages/dashboard/ProfileSettings'))
);

export const LivingWill = Loadable(
  lazy(() => import('../pages/dashboard/LivingWill'))
);

export const PowerAttorney = Loadable(
  lazy(() => import('../pages/dashboard/PowerAttorney'))
);

export const LastWill = Loadable(
  lazy(() => import('../pages/dashboard/LastWill'))
);

export const MarriageContract = Loadable(
  lazy(() => import('../pages/dashboard/MarriageContract'))
);

export const AssetsRegister = Loadable(
  lazy(() => import('../pages/dashboard/AssetsRegister'))
);

export const DigitalFootprint = Loadable(
  lazy(() => import('../pages/dashboard/DigitalFootprint'))
);

export const ImportantInformation = Loadable(
  lazy(
    () =>
      import(
        '../components/pages/ImportantInformation/ImportantInformationForm'
      )
  )
);

export const DigitalAssets = Loadable(
  lazy(() => import('../components/pages/DigitalAssets/DigitalAssets'))
);

export const DFPhotosVideos = Loadable(
  lazy(() => import('../components/pages/DFPhotosVideos'))
);

export const CohabitationAgreement = Loadable(
  lazy(() => import('../pages/dashboard/CohabitationAgreement'))
);

export const LSLanding = Loadable(
  lazy(() => import('../pages/main/LSLandings'))
);
