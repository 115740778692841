const digitalFootprint = {
  title: 'Digitaler Nachlass',
  description:
    'Teilen Sie relevante Informationen und Zugänge mit Ihren Verwandten.',
  dashboardServiceCards: {
    importantInformation: {
      title: 'Wichtige Informationen',
      description:
        'Machen Sie für Ihre Verwandten eine Notiz, wo offizielle Dokumente, Bankdokumente oder Versicherungen aufbewahrt werden, um den Aufwand und den Stress in einer schwierigen Zeit zu reduzieren.',
    },
    digitalAssets: {
      title: 'Digitale Vermögenswerte & Abonnements',
      description:
        'Behalten Sie den Überblick über alle Ihre Abonnements, Social-Media-Profile oder andere Konten und stellen Sie sicher, dass Sie digitale Artefakte und Vermögenswerte an Ihre Nachkommen weitergeben können.',
    },
    picturesAndVideos: {
      title: 'Bilder & Videos',
      description:
        'Stellen Sie sicher, dass Ihre Nachkommen Zugriff auf Fotos und Videos haben, die Sie für die Nachwelt bewahren möchten.',
    },
  },
};

export default digitalFootprint;
