export function getSubdomain(url: string): string | null {
  try {
    const urlObj = new URL(url); // Parse the URL
    const { hostname } = urlObj;

    // Split the hostname into parts
    const parts: string[] = hostname.split('.');

    // Get the top-level domain (TLD)
    const tld: string = parts.slice(-1)[0];
    const ignoredTLDs: Set<string> = new Set(['dev', 'app', 'run']);

    // If the TLD is in the ignored list, return null
    if (ignoredTLDs.has(tld)) {
      return null;
    }

    // Check if there's a subdomain (more than two parts, accounting for TLD and domain)
    if (parts.length > 2) {
      const subdomain: string = parts.slice(0, parts.length - 2).join('.');

      // Ignore specific subdomain cases
      const ignoredSubdomains: Set<string> = new Set([
        'www',
        'dev',
        'app',
        'run',
        'localhost',
      ]);
      if (ignoredSubdomains.has(subdomain)) {
        return null;
      }

      return subdomain;
    }
  } catch (error) {
    console.error('Invalid URL:', error);
  }

  return null;
}
