const provisionLanding = {
  mainBanner: {
    intro: 'Endlich gut vorsorgen:',
    title: 'Patientenverfügung, Vorsorgeauftrag und Testament online erstellen',
    subtitle:
      'Im Notfall sind Ihre Familienmitglieder vielen Emotionen ausgesetzt. Sparen Sie ihnen noch mehr Stress durch ungeklärte Formalitäten.',
    buttonLabel: 'Jetzt registrieren',
  },
  sale: '60% / Ermässigung',
  addMore: '{{membersNo}} weitere hinzufügen',
  youtubeTutorial: 'Warum Vorsorge so wichtig ist?',
  aboutCards: {
    remainInControl: {
      title: 'In Kontrolle bleiben',
      description:
        'Wenn Sie nicht für sich selbst eintreten, werden es andere tun — nicht unbedingt in Ihrem besten Interesse.',
    },
    protectYourFamily: {
      title: 'Familie schützen',
      description:
        'Handlen Sie proaktiv, um zu klären, wie Ihre Familie damit umgehen wird, wenn Sie handlungsunfähig oder nicht mehr hier sind.',
    },
    relieveRelatives: {
      title: 'Angehörige entlasten',
      description:
        'Denken Sie an diejenigen, die viel zu tun haben, wenn Sie nicht mehr in der Lage sind. Sorgen Sie für Entlastung',
    },
  },
  whyWithUs: {
    title: 'Gute Gründe für gut geregelt?',
    cards: {
      card1: {
        title: 'Verständlich',
        description:
          'Werden Sie nach Dingen gefragt, die Sie überhaupt nicht verstehen?! Nicht bei uns. Wir sprechen Ihre Sprache.',
      },
      card2: {
        title: 'Schnell erledigt',
        description:
          'Anstatt Dutzende von Fragen in einem langwierigen Prozess zu beantworten, bekommen Sie, was Sie möchten, innerhalb von 10 Minuten - garantiert.',
      },
      card3: {
        title: 'Preiswert',
        description:
          'gut geregelt ermöglicht es Ihnen, umfassende und rechtlich konforme Vorsorge zu einem unschlagbaren Preis zu treffen.',
      },
    },
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'Mein Mann und ich diskutieren schon seit Jahren über das Thema. Durch unsere Tochter sind wir auf gut geregelt aufmerksam gemacht worden. Wir waren erstaunt, wie einfach und schnell wir erledigen konnten, was wir Jahre lang vor uns hergeschoben haben.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: 'Mein Vater war Unternehmen der alten Schule. Viel beschäftigt, wenig dokumentiert. Als er verstorben ist, mussten meine Schwester und ich mühselig Klarheit und Ordnung schaffen. Obwohl ich noch nicht im Alter bin, will ich kein Risiko eingehen und meiner Familie eine ähnliche Bürde ersparen. gut geregelt hat hier mir ermöglicht, genau das zu tun. Und das vom Sofa aus.',
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: 'Vor 2 Jahren hab ich mit meinem Anwalt ein Testament erstellt. Dank gut geregelt hab ich jetzt auch eine Patientenverfügung und einen Vorsorgeauftrag. Der Prozess war übersichtlich und vor allem die Sprache klar verständlich. Obwohl ich hoff, dass ich sie nie brauch, bin ich froh, hab ich mich auch zum Erstellen dieser Dokumente überwunden.',
    },
  },
  subBanner: {
    title: 'Rechtlich konforme Vorsorge, leicht gemacht',
    subtitle:
      'gut geregelt bietet bei Bedarf Zugang zu rechtlicher Beratung und hilft Ihnen, rechtlich konforme Dokumente zu erstellen, sie sicher aufzubewahren und sie im Notfall mit denen zu teilen, die sie benötigen.',
    buttonLabel: 'Jetzt loslegen',
  },
  pricing: {
    title: 'Das richtige Paket für Sie',
    subtitle:
      'Wir bieten für jeden etwas. Wählen Sie jetzt das Paket, das am besten Ihren Bedürfnissen entspricht.',
    oneTime: 'einmalig',
    perYear: 'Jahr',
    additionalMember: 'weiteres Mitglied',
    purchaseButton: 'Jetzt vorsorgen',
    manageButton: 'Verwalten',
    starter: {
      title: 'Starter',
      finfinderSale: 'Sparen Sie 200 CHF mit FinFinder',
      features: [
        'Alle Vorsorgedokumente',
        'Digitale Dokumentenverwaltung',
        'Vermögens- und Sachregister',
        'Digitaler Nachlass',
        'Steuerordner',
        'Unterstützung beim Aufräumen',
        'Gastzugang für Vertreter',
        'Zugang zu rechtlicher und finanzieller Beratung',
      ],
    },
    standard: {
      title: 'Standard',
      finfinderSale: 'Sparen Sie 400 CHF mit FinFinder',
      features: [
        'Alle Vorsorgedokumente',
        'Digitale Dokumentenverwaltung',
        'Vermögens- und Sachregister',
        'Digitaler Nachlass',
        'Steuerordner',
        'Unterstützung beim Aufräumen',
        'Gastzugang für Vertreter',
        'Erinnerungen (Dokumente erledigen, rechtliche Aktualisierungen und andere Änderungen)',
        'Zugang zu rechtlicher und finanzieller Beratung',
      ],
    },
  },
  provisionBanner: {
    title:
      'Auch wenn es schwer fällt ... Es ist wichtig, jetzt darüber nachzudenken',
    subtitle:
      'Viele von uns neigen dazu, die Altersvorsorge aufzuschieben. Schliesslich geniesst niemand das Nachdenken über das schlimmstmögliche Szenario. Es ist jedoch wichtig zu bedenken, dass Sie Ihren Liebsten und Ihnen selbst keinen Gefallen tun, wenn Sie es vernachlässigen. Je früher Sie beginnen, desto besser. Wir helfen Ihnen, den richtigen Altersvorsorgeplan für Sie zu finden.',
    buttonLabel: 'Jetzt vorsorgen',
  },
  newsletter: {
    title: 'Werden Sie gut geregelt',
    buttonLabel: 'Erstellen Sie Ihre Dokumente',
  },

  eightReasons: {
    title: '8 Gründe für gut geregelt',
    cards: {
      card1: {
        title: 'Einfach und verständlich',
        description: {
          provisionLanding:
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
          patientenverfuegung:
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
          vorsorgeauftrag:
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
          testament:
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
          ehevertrag:
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
          'digitaler-nachlass':
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
          konkubinatsvertrag:
            'Wir sprechen Ihre Sprache und machen komplexe Begriffe leicht verständlich.',
        },
      },
      card2: {
        title: 'In 30 Minuten erledigt',
        description: {
          provisionLanding:
            'Ihr fertiges Vorsorgedokument in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
          patientenverfuegung:
            'Ihr fertiges Patientenverfügung in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
          vorsorgeauftrag:
            'Ihr fertiges Vorsorgeauftrag in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
          testament:
            'Ihr fertiges testament in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
          ehevertrag:
            'Ihr fertiges Ehevertrag in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
          'digitaler-nachlass':
            'Ihr fertiges Digitaler Nachlass in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
          konkubinatsvertrag:
            'Ihr fertiges Konkubinatsvertrag in nur 14 Minuten durchschnittlich durch unseren optimierten Prozess.',
        },
      },
      card3: {
        title: 'Bequem von zuhause',
        description: {
          provisionLanding:
            'Erstellen Sie Ihre Dokumente entspannt von zuhause aus, ohne Termine, wann immer es Ihnen passt.',
          patientenverfuegung:
            'Erstellen Sie Ihre Patientenverfügung bequem von zu Hause aus, ohne Termin, wann immer es Ihnen passt.',
          vorsorgeauftrag:
            'Erstellen Sie Ihren Vorsorgeauftrag bequem von zu Hause aus, ohne Termin, wann immer es Ihnen passt.',
          testament:
            'Erstellen Sie Ihr Testament bequem von zu Hause aus, ohne Termin, wann immer es Ihnen passt.',
          ehevertrag:
            'Erstellen Sie Ihren Ehevertrag bequem von zu Hause aus, ohne Termin, wann immer es Ihnen passt.',
          'digitaler-nachlass':
            'Erstellen Sie Ihren Digitaler Nachlass bequem von zu Hause aus, ohne Termin, wann immer es Ihnen passt.',
          konkubinatsvertrag:
            'Erstellen Sie Ihren Konkubinatsvertrag bequem von zu Hause aus, ohne Termin, wann immer es Ihnen passt.',
        },
      },
      card4: {
        title: 'Jederzeit anpassbar',
        description: {
          provisionLanding:
            'Passen Sie Ihre Dokumente jederzeit einfach und unkompliziert an neue Lebensumstände an.',
          patientenverfuegung:
            'Passen Sie Ihre Patientenverfügung jederzeit einfach und unkompliziert an neue Lebensumstände an.',
          vorsorgeauftrag:
            'Passen Sie Ihren Vorsorgeauftrag jederzeit einfach und unkompliziert an neue Lebensumstände an.',
          testament:
            'Passen Sie Ihr Testament jederzeit einfach und unkompliziert an neue Lebensumstände an.',
          ehevertrag:
            'Passen Sie Ihren Ehevertrag jederzeit einfach und unkompliziert an neue Lebensumstände an.',
          'digitaler-nachlass':
            'Passen Sie Ihren Digitaler Nachlass jederzeit einfach und unkompliziert an neue Lebensumstände an.',
          konkubinatsvertrag:
            'Passen Sie Ihren Konkubinatsvertrag jederzeit einfach und unkompliziert an neue Lebensumstände an.',
        },
      },
      card5: {
        title: 'Rechtssicher',
        description: {
          provisionLanding:
            'Erstellen Sie rechtssichere Dokumente, geprüft von Experten und stets aktuell.',
          patientenverfuegung:
            'Erstellen Sie rechtssichere Patientenverfügung, geprüft von Experten und stets aktuell.',
          vorsorgeauftrag:
            'Erstellen Sie rechtssichere Vorsorgeauftrag, geprüft von Experten und stets aktuell.',
          testament:
            'Erstellen Sie rechtssichere Testament, geprüft von Experten und stets aktuell.',
          ehevertrag:
            'Erstellen Sie rechtssichere Ehevertrag, geprüft von Experten und stets aktuell.',
          'digitaler-nachlass':
            'Erstellen Sie rechtssichere Digitaler Nachlass, geprüft von Experten und stets aktuell.',
          konkubinatsvertrag:
            'Erstellen Sie rechtssichere Konkubinatsvertrag, geprüft von Experten und stets aktuell.',
        },
      },
      card6: {
        title: 'Sichere Verwahrung',
        description: {
          provisionLanding: 'Sichere Aufbewahrung aller Dokumente.',
          patientenverfuegung: 'Sichere Aufbewahrung von Patientenverfügung.',
          vorsorgeauftrag: 'Sichere Aufbewahrung von Vorsorgeauftrag.',
          testament: 'Sichere Aufbewahrung von Testament.',
          ehevertrag: 'Sichere Aufbewahrung von Ehevertrag.',
          'digitaler-nachlass': 'Sichere Aufbewahrung von Digitaler Nachlass.',
          konkubinatsvertrag: 'Sichere Aufbewahrung von Konkubinatsvertrag.',
        },
      },
      card7: {
        title: 'Zugang für wichtige Personen',
        description: {
          provisionLanding:
            'Mitteilung und Zugang zu den relevanten Dokumenten für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
          patientenverfuegung:
            'Mitteilung und Zugang zu den relevanten Patientenverfügung für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
          vorsorgeauftrag:
            'Mitteilung und Zugang zu den relevanten Vorsorgeauftrag für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
          testament:
            'Mitteilung und Zugang zu den relevanten Testament für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
          ehevertrag:
            'Mitteilung und Zugang zu den relevanten Ehevertrag für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
          'digitaler-nachlass':
            'Mitteilung und Zugang zu den relevanten Digitaler Nachlass für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
          konkubinatsvertrag:
            'Mitteilung und Zugang zu den relevanten Konkubinatsvertrag für Angehörige, Ärzte oder Willensvollstrecker im richtigen Moment.',
        },
      },
      card8: {
        title: 'Preiswert',
        description: {
          provisionLanding:
            'Umfassende und rechtlich konforme Vorsorge zu einem Bruchteil der Kosten eines Notars.',
          patientenverfuegung:
            'Umfassende und rechtlich konforme Patientenverfügung zu einem Bruchteil der Kosten eines Notars.',
          vorsorgeauftrag:
            'Umfassende und rechtlich konforme Vorsorgeauftrag zu einem Bruchteil der Kosten eines Notars.',
          testament:
            'Umfassende und rechtlich konforme Testament zu einem Bruchteil der Kosten eines Notars.',
          ehevertrag:
            'Umfassende und rechtlich konforme Ehevertrag zu einem Bruchteil der Kosten eines Notars.',
          'digitaler-nachlass':
            'Umfassende und rechtlich konforme Digitaler Nachlass zu einem Bruchteil der Kosten eines Notars.',
          konkubinatsvertrag:
            'Umfassende und rechtlich konforme Konkubinatsvertrag zu einem Bruchteil der Kosten eines Notars.',
        },
      },
    },
  },
  betterRegulatedLife: {
    title: 'Ein gut geregeltes Leben',
    provisionCheck: {
      title: 'Vorsorge-Check',
      subtitle:
        'Wir verstehen Ihre Lebenssituation. Erfahren Sie in zwei Minuten, was Sie für eine gut geregelte Vorsorge brauchen. Wir holen Sie dort ab, wo Sie stehen.',
      buttonLabel: 'Vorsorgecheck starten',
    },
    lifeSituationCheck: {
      title: 'Lebenssituations-Check',
      subtitle:
        'Steht bei Ihnen ein neuer Lebensabschnitt bevor oder haben Sie ein einschneidendes Erlebnis gehabt und machen sich Gedanken, wie das Ihre Vorsorge beeinflusst?',
      buttonLabel: 'Lebenssituations-Check starten',
    },
  },
};
export default provisionLanding;
